import { startPetSearchEventHandler } from '@/lib/utils/analytics/startPetSearch';

/**
 * MenuLink
 *
 * @type {object} MenuLink
 */
export type MenuLink = {
  /** The name of the link. */
  name: string;
  /** The url of the link. */
  url: string;
  /** The link target */
  target?: string;
  /**
   * The optional on click function
   *
   * @returns {void}
   */
  onClick?: () => void;
};

/**
 * IMenuItem
 *
 * @interface
 */
export interface IMenuItem {
  /** The name of the menu item. */
  name: string;
  /** The links to display in the menu item. */
  links: MenuLink[];
}

export const footerColumnOneLinks: IMenuItem = {
  name: 'Who We Are',
  links: [
    {
      name: 'About',
      url: '/about',
    },
    {
      name: 'Get in Touch',
      url: 'https://support.lost.petcolove.org/hc/en-us',
      target: '_blank',
    },
  ],
};

export const footerColumnTwoLinks: IMenuItem = {
  name: 'Our Family',
  links: [
    {
      name: 'Petco Love',
      url: 'https://petcolove.org',
      target: '_blank',
    },
    {
      name: 'Petco Love Adopt',
      url: 'https://petcolove.org/adopt/',
      target: '_blank',
    },
    {
      name: 'Petco Love Care',
      url: 'https://petcolove.org/care/',
      target: '_blank',
    },
    {
      name: 'Petco.com',
      url: 'https://www.petco.com/shop/en/petcostore',
      target: '_blank',
    },
  ],
};
export const footerColumnThreeLinks: IMenuItem = {
  name: 'Partners',
  links: [
    {
      name: 'Supporters',
      url: '/partners',
    },
    {
      name: 'Petco Love Partner Portal',
      url: 'https://petcolove.fluxx.io/user_sessions/new',
      target: '_blank',
    },
  ],
};

/**
 * The menu item for the I Lost a Pet link
 *
 * @constant {MenuLink}
 */
const footerColumnFourILostAPetLinks: MenuLink = {
  name: 'I Lost a Pet',
  url: '/i-lost-a-pet',
};

/**
 * The menu item for the I Found a Pet link
 *
 * @constant {MenuLink}
 */
const footerColumnFourIFoundAPetLinks: MenuLink = {
  name: 'I Found a Pet',
  url: '/i-found-a-pet',
};

export const footerColumnFourLinks: IMenuItem = {
  name: 'Pet Search',
  links: [
    {
      ...footerColumnFourILostAPetLinks,
      onClick:
        /**
         * The on click function
         *
         * @returns {void}
         */
        (): void => {
          const { name: iLostAPetClickText } = footerColumnFourILostAPetLinks;
          startPetSearchEventHandler({
            clickText: iLostAPetClickText,
            component: 'I Lost A Pet Footer Link / All Pages',
            flow: 'Found',
          });
        },
    },
    {
      ...footerColumnFourIFoundAPetLinks,
      onClick:
        /**
         * The on click function
         *
         * @returns {void}
         */
        (): void => {
          const { name: iFoundAPetClickText } = footerColumnFourIFoundAPetLinks;
          startPetSearchEventHandler({
            clickText: iFoundAPetClickText,
            component: 'I Found A Pet Footer Link / All Pages',
            flow: 'Lost',
          });
        },
    },
  ],
};

export const footerColumnFourLoggedInLinks: IMenuItem = {
  name: 'Pet Search',
  links: [
    {
      name: 'Manage Pets',
      url: '/dash',
    },
  ],
};

export const bottomLinks: MenuLink[] = [
  {
    name: 'Privacy Policy',
    url: '/privacy-policy',
  },
  {
    name: 'Terms of Service',
    url: '/terms-of-service',
  },
];
